import React from "react";
import { Navbar } from "reactstrap";
import logo from "../assets/img/ox-logo.png";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import LanguageSelector from "./LanguageSelector";
import { checkEmptyObject } from "../utils"
import { moduleList } from "../containers/Home/moduleList";
import ConfirmBox from "../components/ConfirmationBox";
import { MODULE_PREREQUISITES_COMPLETED } from "../containers/Home/constants";

export default class NavbarComponent extends React.Component {
  render() {
    let { selectedProject, home, t } = this.props;
    return (
      <Navbar dark expand="md" fixed="top">
        <table className="navbar-container">
          <tbody>
            <tr>
              <td align="left" width="75">
                <div className="navbar-logo">
                  <div style={{ cursor: "pointer" }} onClick={this.props.redirectToHome}>
                    <img alt="logo" className="img-fluid logo" src={logo} />
                  </div>
                </div>
              </td>
              {
                checkEmptyObject(home.selectedModule) || home.prerequisiteState !== MODULE_PREREQUISITES_COMPLETED ?
                  <>
                    <td align="right">
                      <table>
                        <tbody>
                          <tr>
                            <td align="left">
                              <div className="navbar-project">{selectedProject.name && <SelectedProject {...this.props} />}</div>
                            </td>
                            {selectedProject.name && <td className="navbar-seprator"></td>}
                            <td align="left">
                              <div className="navbar-account">
                                <UserOptions {...this.props} />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </> :
                  <>
                    <td align="left">
                      <div className="navbar-module-name">{t(moduleList.filter(x => x.action === home.selectedModule)[0].name)}</div>
                    </td>
                    {
                      moduleList.filter(x => x.action === home.selectedModule)[0].menu.length > 0 &&
                      <td align="right">
                        <table style={{ marginRight: 10 }}>
                          <tbody>
                            <tr>
                              <td align="left">
                                <div className="navbar-module-menu"><ModuleOptions {...this.props} /></div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    }
                  </>
              }
            </tr>
          </tbody>
        </table>
      </Navbar>
    );
  }
}

class UserOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      isLogoutButtonDisplay: false
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  redirectMainApplication = () => {
    let mainApplication = window.location.hostname;
    if (window.location.hostname.indexOf("localhost") !== -1) {
      mainApplication = "dev.ociusx.com";
    } else {
      mainApplication = mainApplication.replace("app.", "");
    }
    window.location.href = `//${mainApplication}/modules/myaccount`;
  };
  handleOnLogoutConfirmCheckBoxClick = (target, value) => {
    this.setState({ isLogoutButtonDisplay: value });
  };
  handleOnLogoutClick = () => {
    this.setState({ isLogoutButtonDisplay: false });
    this.props.logout();
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret className="btn-navbar">
            <i className="fa fa-fw fa-user" /> {t("label.Account")}
          </DropdownToggle>
          <DropdownMenu className="z-index-top">
            <DropdownItem onClick={this.redirectMainApplication}>&nbsp;{t("control.Manage")}</DropdownItem>
            <LanguageSelector cssClass="dropdown-custome-item" {...this.props} />
            <DropdownItem onClick={this.handleOnLogoutClick}>&nbsp;{t("control.Log_out")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {
          this.props.auth.isRoqOfflineDataExists &&
          <ConfirmBox
            confirmButtonText={t("control.Log_out")}
            cancelButtonText={t("text.Cancel")}
            heading={t("text.Warning")}
            text={t("text.Confirm_deletion_of_data_on_log_out_message")}
            onConfirm={this.props.performLogout}
            onCancel={this.props.declineLogout}
            checkboxes={[{ id: "chk1", name: "chk1", text: t("text.Confirm_deletion_of_data"), event: this.handleOnLogoutConfirmCheckBoxClick }]}
            buttomTheme={1}
            isConfirmButtonVisible={this.state.isLogoutButtonDisplay}
          >
          </ConfirmBox>
        }
      </>
    );
  }
}

class ModuleOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { home, t } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret className="btn-navbar">{t("control.Menu")}</DropdownToggle>
        <DropdownMenu className="z-index-top">
          {
            moduleList.filter(x => x.action === home.selectedModule)[0].menu.map((menu, i) => (
              <DropdownItem key={i} onClick={(e) => this.props.redirect(`/${menu.action}`)}>{`${t("label.Select")} ${t(menu.name)}`}</DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    );
  }
}

class SelectedProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    let selectedProject = this.props.selectedProject;
    const { t } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret className="btn-navbar">
          {selectedProject.name}
        </DropdownToggle>
        <DropdownMenu className="z-index-top">
          <DropdownItem onClick={(e) => this.props.redirect("/projects")}>
            <i className="fa fa-list-alt mr-1"></i> {t("control.Change_project")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
