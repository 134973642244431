import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { redirectOnModule, removeModule } from "./actions";
import { withTranslation } from "react-i18next";
import MenuButton from "../../components/MenuButton";
import { moduleList } from "./moduleList.js"
import AlertBox from "../../components/AlertBox";
import { checkEmptyObject } from "../../utils";
import { MODULE_PREREQUISITES_INIT } from "./constants";

class ModulePage extends React.Component {

    constructor(props) {
        super(props);
        // In the .filter() we need to check if props.users.modules exists since this page (/home) renders even if we are not logged and try to access it.
        this.state = {
            modules: moduleList.filter(mod => props.user.modules && props.user.modules.some(mods => mods === mod.uniqueKey)),
            clickedOn: "",
            isLoading: false
        };
        this.props.removeModule();
    }
    componentDidMount() {
        let { selectedModule, prerequisiteState } = this.props.local;
        if (!checkEmptyObject(selectedModule) && prerequisiteState === MODULE_PREREQUISITES_INIT) {
            this.handleSelect(moduleList.filter(mod => mod.action === selectedModule)[0])
        }
    }

    handleSelect = (module) => {
        if (module.isImplementedWithApp) {
            this.setState({ isLoading: true, clickedOn: module.uniqueKey });
            this.props.redirectOnModule(module.action, this.props);
        }
        else {
            this.setState({ isLoading: false, clickedOn: module.uniqueKey });
            //Fetch and create appplication domain to redirect user on module landing page.
            let mainApplication = window.location.hostname;
            if (window.location.hostname.indexOf("localhost") !== -1) {
                mainApplication = "dev.ociusx.com";
            }
            else {
                mainApplication = mainApplication.replace("app.", "");
            }

            let url = `//${mainApplication}${module.action}`;
            if (module.doSendProjectId && this.props.customerProject.id !== undefined && this.props.customerProject.id > 0) {
                // The project that the user has selected in React also needs to transfer to the .Net module. 
                // So that this project will be selected there as well.
                url = `${url}?pr_id=${this.props.customerProject.id}`;
            }
            window.location.href = url;
        }
    };
    handleOnOk = () => {
        this.setState({ isLoading: false });
        this.props.removeModule();
    }

    render() {
        const { t, local } = this.props;
        const { isLoading, clickedOn } = this.state;
        const { isFailPrerequisites, messageToAlert } = local;
        return (
            <>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12 mt-n2 user-name">
                            <h5>{`${t("label.Welcome")} ${this.props.user.name}`}</h5>
                        </div>
                        <div className="col-lg-12">
                            <div className="menu-padding-box bg-box">
                                {
                                    this.state.modules.map((data, index) => (
                                        <MenuButton
                                            key={index}
                                            cssClass="btn-info"
                                            onClick={(menu) => this.handleSelect(data)}
                                            text={t(data.name)}
                                            icon={data.icon}
                                            theme={data.theme}
                                            loading={clickedOn === data.uniqueKey ? isLoading : false}></MenuButton>
                                    ))
                                }
                            </div>
                            {/* Showing app build version number */}
                            <div className="app-version">{process.env.NODE_ENV === "development" ? 'Development build' : `ver: ${process.env.REACT_APP_VERSION}`}</div>
                        </div>
                    </div>
                </div>

                {isFailPrerequisites &&
                    <AlertBox
                        okButtonText={t("control.OK")}
                        heading={t("text.Information")}
                        text={messageToAlert}
                        onOk={this.handleOnOk}
                    ></AlertBox>
                }
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    local: state.modules,
    online: state.app.online,
    user: state.auth.user,
    customerProject: state.selectProject.selectedProject,
    customerTeam: state.selectTeam.selectedTeam,
    customerCodeListType: state.selectCodeListType.selectedCodeListType,
});

const mapDispatchToProps = dispatch => ({
    redirect: (path) => dispatch(push(path)),
    redirectOnModule: (module, props) => dispatch(redirectOnModule(module, props)),
    removeModule: () => dispatch(removeModule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModulePage));
