import * as constant from "./constants";

const initialState = {
    isFailPrerequisites: false,
    messageToAlert: "",
    selectedModule: localStorage.getItem("selectedModule") || {},
    prerequisiteState: constant.MODULE_PREREQUISITES_NOT_SET
};
const modules = (state = initialState, action) => {
    switch (action.type) {
        case constant.MODULE_SELECT_INIT:
            localStorage.setItem("selectedModule", action.payload);
            return {
                ...state,
                selectedModule: action.payload,
                prerequisiteState: constant.MODULE_PREREQUISITES_INIT,
            }
        case constant.MODULE_SELECT:
            localStorage.setItem("selectedModule", action.payload);
            return {
                ...state,
                selectedModule: action.payload,
                prerequisiteState: constant.MODULE_PREREQUISITES_COMPLETED,
            }
        case constant.MODULE_REMOVE:
            localStorage.removeItem("selectedModule");
            return {
                ...state,
                selectedModule: {},
                isFailPrerequisites: false,
                messageToAlert: "",
                prerequisiteState: constant.MODULE_PREREQUISITES_NOT_SET,
            }
        case constant.MODULE_PREREQUISITES_RESULT:
            return {
                ...state,
                isFailPrerequisites: action.payload.isFail,
                messageToAlert: action.payload.message,
            }
        case constant.LOGOUT_CLEAR_MODULE:
            localStorage.removeItem("selectedModule");
            return {
                ...state,
                selectedModule: {}
            }
        default:
            return state;
    }
};
export default modules;